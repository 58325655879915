<ng-scrollbar shown="hover" compact="true">
    <sidebar-user></sidebar-user>

    <ul class="navigation">
        <li *ngFor="let menu of mainMenu; let i = index" [class.navigation__sub]="menu.sub" routerLinkActive="navigation__active">
            <!-- Router link for non sub menu  -->
            <a *ngIf="!menu.sub" [routerLink]="[menu.route]" (click)="menu.sub && toggleSubMenu(i)">
                <i [ngClass]="['zmdi zmdi-'+menu.icon]"></i> {{ menu.title }}
            </a>
            <!-- / -->

            <!-- Menu toggle for sub menu -->
            <a *ngIf="menu.sub" (click)="menu.sub && toggleSubMenu(i)">
                <i [ngClass]="['zmdi zmdi-'+menu.icon]"></i> {{ menu.title }}
            </a>
            <!-- / -->

            <ul *ngIf="menu.sub" [@toggleSubMenu]="menu.visibility">
                <li *ngFor="let sub of menu.sub" routerLinkActive="navigation__active">
                    <a [routerLink]="[sub.route]">{{ sub.title }}</a>
                </li>
            </ul>
        </li>
    </ul>
</ng-scrollbar>